html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  overscroll-behavior: none;
}



.headerBlock {
  display: flex;
  justify-content: center;
  align-items: center; 
  position: absolute; 
  height: 100%;
  width: 100%;
  overflow: hidden;
  will-change: opacity;
}

/* @media (max-width:660px) {
  .headerBlock {
    height: auto;
    width: auto;
    left: 10vw;
    bottom: 10vw;
  }
} */

.headerG {
  /* color: rgb(0, 0, 0);
  font-family: Avenir Next;
  font-size: 40px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 6.6px; */

  width: 35vw;
}

.Video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 95%;
}

/* .Sphere{
  background: radial-gradient(50% 50.00% at 50% 50.00%, #000 0%, #000 68.23%, rgba(0, 0, 0, 0.00) 100%);
  filter: blur(50px);
} */

.Noise{
  /* background: lightgray 0% 0% / 100px 100px repeat; */
  /* background: radial-gradient(50% 50.00% at 50% 50.00%, #000 0%, #000 100%); */
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  mix-blend-mode: overlay;
}

.Sphere{
  position: absolute;
  z-index: -2;
  width: 72vw;
  height: 72vw;
  /* width: calc(35vw * 2.1);
  height: calc(35vw * 2.1); */
  /* width: 600px; */
  /* height: 600px; */
  border-radius: 50%;
  /* background: lightgray 0% 0% / 100px 100px repeat; */
  /* background: radial-gradient(50% 50.00% at 50% 50.00%, #000 0%, #000 100% rgba(0, 0, 0, 0.00) 100%); */
  /* background: radial-gradient(50% 50.00% at 50% 50.00%, #000 0%, #000 100%); */
  background-image: radial-gradient(50% 50.00% at 50% 50.00%, #000 0%, #000 20.31%, rgba(0, 0, 0, 0.00) 100%);
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  filter: blur(50px);
  /* translate: 0px; */
  will-change: background-image;
}

@media (max-width:660px) {
  body {
    width:100%;
    height:100%;
  }

  .Sphere {
    filter: blur(20px);
  }

  .headerG{
    width: 75vw;
  }

  .Sphere{
    /* width: calc(75vw * 2.1); */
    /* height: calc(75vw * 2.1); */
    width: 154vw;
    height: 154vw;
    filter: blur(20px);
  }
}